import Vue from 'vue'
import Router from 'vue-router'
import { initGuard } from '@/router/routerGuard'

Vue.use(Router)

let router = new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: [
        {
            path: '/',
            component: () => import('@/views/index.vue'),
            meta: { title: '导航' },
        },
        {
            path: '/login',
            component: () => import('@/views/login.vue'),
            meta: { title: '登录' },
        },
        // 404路由，应该放到最底部
        {
            path: '*',
            component: () => import('@/views/404.vue'),
            meta: { title: '404' },
        }
    ]
})

initGuard(router)

export default router