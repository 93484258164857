import utils from "./utils"

export default {
    floatformat(value, fix) {
        value = Number(value)
        return value.toFixed(fix)
    },
    ts_2_dt(value, format = 'YYYY-MM-DD HH:mm:ss') {
        if (!value) {
            return ''
        }
        let date = new Date(value * 1000)
        return utils.dateFormat(date, format)
    },
    basename(value) {
        return utils.basename(value)
    }
}