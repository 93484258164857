import Vue from 'vue'
import App from './App.vue'
import router from '@/router/router.js'
import filters from '@/common/js/filter'

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

Vue.use(ElementUI);

Vue.config.productionTip = false

// 加载 vue filter
Object.keys(filters).forEach(k => Vue.filter(k, filters[k]))

export default new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
