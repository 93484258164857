import vm from '@/main'

function showLoading() {
    // 显示loading框
    if (vm) {
        vm.$loading()
    }
}

function hideLoading() {
    // 关闭loading框
    if (vm) {
        vm.$loading().close()
    }
}

function vueMessage(message, type = 'success') {
    if(vm) {
        vm.$message({message, type})
    }
}

function ts_2_dt(value, format = 'YYYY-MM-DD HH:mm:ss') {
    if (!value) {
        return ''
    }
    let date = new Date(value * 1000)
    return dateFormat(date, format)
}

function dateFormat(date, fmt = 'YYYY-MM-DD HH:mm:ss') {
    // 日期格式化, 相对moment来说十分轻量的实现
    if (!date) {
        return ''
    }
    if (typeof date === 'string') {
        date = new Date(date.replace(/-/g, '/'))
    }
    if (typeof date === 'number') {
        date = new Date(date)
    }
    let o = {
        'M+': date.getMonth() + 1,
        'D+': date.getDate(),
        'h+': date.getHours() % 12 === 0 ? 12 : date.getHours() % 12,
        'H+': date.getHours(),
        'm+': date.getMinutes(),
        's+': date.getSeconds(),
        'q+': Math.floor((date.getMonth() + 3) / 3),
        'S': date.getMilliseconds()
    }
    let week = {
        '0': '\u65e5',
        '1': '\u4e00',
        '2': '\u4e8c',
        '3': '\u4e09',
        '4': '\u56db',
        '5': '\u4e94',
        '6': '\u516d'
    }
    if (/(Y+)/.test(fmt)) {
        fmt = fmt.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length))
    }
    if (/(E+)/.test(fmt)) {
        fmt = fmt.replace(RegExp.$1, ((RegExp.$1.length > 1) ? (RegExp.$1.length > 2 ? '\u661f\u671f' : '\u5468') : '') + week[date.getDay() + ''])
    }
    for (let k in o) {
        if (new RegExp('(' + k + ')').test(fmt)) {
            fmt = fmt.replace(RegExp.$1, (RegExp.$1.length === 1) ? (o[k]) : (('00' + o[k]).substr(('' + o[k]).length)))
        }
    }
    return fmt
}

function basename(str) {
    // 根据路径 提取文件名
    var idx = str.lastIndexOf('/')
    idx = idx > -1 ? idx : str.lastIndexOf('\\')
    if (idx < 0) {
        return str
    }
    return str.substring(idx + 1);
}

function getFullName(str) {
    // 根据路径 提取文件名(不含后缀)
    let data = basename(str)
    return data.substring(0,data.indexOf("."));
}

// 判断两个数组是否相等
function equar(arr1, arr2) {
    if(arr1.length !== arr2.length) {
        return false
    }
    for(let i = 0; i < arr1.length; i ++) {
        if(arr1[i] !== arr2[i]) {
            return false
        }
    }
    return true
}

// 数组深拷贝
function copyArr(arr) {
    let res = []
    for(let i = 0; i < arr.length; i ++) {
        res.push(arr[i])
    }
    return res
}

function ltrim(s) {
    return s.replace(/^\s*/g, '');
}
  
function rtrim(s) {
    return s.replace(/\s*$/g, '');
}

// 去除前后空格
function trim(s) {
    return rtrim(ltrim(s));
}

// date-2-yyyy/mm/dd
function dateToString1(dt) {
    let mm = dt.getMonth() + 1; // getMonth() is zero-based
    let dd = dt.getDate();
  
    return [
      dt.getFullYear(),
      (mm > 9 ? '' : '0') + mm,
      (dd > 9 ? '' : '0') + dd
    ].join('-');
  }

function firstDayOfMonth(dt) {
    return new Date(dt.getFullYear(), dt.getMonth(), 1);
}

function lastDayOfMonth(dt) {
    return new Date(dt.getFullYear(), dt.getMonth() + 1, 0)
}

function todayPicker() {
    var today = new Date();
    return {
      'desc': '今天',
      'start_dt': dateToString1(today),
      'end_dt': dateToString1(today),
    };
  }
  
  function prevDay() {
    var date = new Date();
    date.setDate(date.getDate()-1)
  
    return {
      'desc': '昨天',
      'start_dt': dateToString1(date),
      'end_dt': dateToString1(date),
    }
  }

function thisMonth() {
    var today = new Date();
    var firstDay = firstDayOfMonth(today);
    return {
        'desc': '本月',
        'start_dt': dateToString1(firstDay),
        'end_dt': dateToString1(today),
    };
}
  
function prevMonth() {
    var date = new Date();
    var firstDay = new Date(date.getFullYear(), date.getMonth() - 1, 1);
    var lastDay = new Date(date.getFullYear(), date.getMonth(), 0);
    return {
        'desc': '上个月',
        'start_dt': dateToString1(firstDay),
        'end_dt': dateToString1(lastDay),
    };
}

export default {
    showLoading,
    hideLoading,
    vueMessage,
    dateFormat,
    basename,
    getFullName,
    equar,
    copyArr,
    trim,
    dateToString1,
    firstDayOfMonth,
    lastDayOfMonth,
    todayPicker,
    prevDay,
    thisMonth,
    prevMonth,
    ts_2_dt
}